import { template as template_355b6b4f73f747069d2c867c9b1e7142 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
const PrivateMessageGlyph = template_355b6b4f73f747069d2c867c9b1e7142(`
  {{#if @shouldShow}}
    {{#if @href}}
      <a href={{@href}} title={{i18n @title}} aria-label={{i18n @ariaLabel}}>
        <span class="private-message-glyph-wrapper">
          {{icon "envelope" class="private-message-glyph"}}
        </span>
      </a>
    {{~else}}
      <span class="private-message-glyph-wrapper">
        {{icon "envelope" class="private-message-glyph"}}
      </span>
    {{~/if}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PrivateMessageGlyph;
