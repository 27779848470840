import { template as template_48de71af2b9b4513820f9dbf9c91901e } from "@ember/template-compiler";
const FKControlMenuContainer = template_48de71af2b9b4513820f9dbf9c91901e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
