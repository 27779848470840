import { template as template_8ad6e16161014ebba91b3c5e7a4eca29 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8ad6e16161014ebba91b3c5e7a4eca29(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
