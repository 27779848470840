import { template as template_efc6eb1aa4774b029d75256650e02efa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_efc6eb1aa4774b029d75256650e02efa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
